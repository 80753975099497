<template>
  <div>
    <div v-if="permissions['devolucao.index'].permissao">
      <b-row>
        <b-col class="justify-content-end d-flex">
          <b-button
            v-if="permissions['devolucao.store'].permissao"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="mb-1"
            variant="primary"
            @click="pushCreate"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span class="align-middle">Nova Devolução</span>
          </b-button>
        </b-col>
      </b-row>
      <b-card>
        <!-- <div v-if="load">
          <b-row align-h="center">
            <b-col
              cols="auto"
              class=""
            >
              <b-spinner style="width: 10rem; height: 10rem;" />
            </b-col>
          </b-row>
        </div> -->
        <b-overlay
          :show="load"
          variant="transparent"
        >
          <b-row>
            <b-col
              cols="auto"
              class="mx-auto"
            >
              <b-alert
                variant="danger"
                show
              >
                <h4 class="alert-heading">
                  Devoluções: {{ totalRows }}
                </h4>
              </b-alert>
            </b-col>
            <b-col cols="12">
              <b-row align-h="between">
                <b-col
                  lg="1"
                  class="my-1 mt-3"
                >
                  <b-form-group
                    class="mb-0"
                  >
                    <b-form-select
                      id="perPageSelect"
                      v-model="perPage"
                      size="sm"
                      :options="[10, 15, 20]"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  md="3"
                  class="my-1"
                >
                  <label>Filtro Motivo Devolução</label>
                  <v-select
                    v-model="devolucaoFilter"
                    :options="optionsDevolucao"
                    multiple
                  />
                </b-col>
                <b-col
                  md="8"
                  class="my-1 mt-3"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filterInput"
                      v-model="filter"
                      type="search"
                      placeholder="Pesquisar"
                    />
                  </b-input-group>
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="12">
              <b-table
                striped
                bordered
                responsive
                selectable
                select-mode="single"
                :per-page="perPage"
                :current-page="currentPage"
                :items="filteredItems"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                @row-clicked="rowClick"
              />
            </b-col>

            <b-col
              cols="12"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-overlay>
      </b-card>
    </div>
    <div v-else>
      <not-authorized />
    </div>
  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BButton,
  BCard,
  BOverlay,
  BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import notAuthorized from '@/views/misc/notAuthorized.vue'
import axios from '@/../axios-auth'
import { util } from '@/libs/util'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BButton,
    BCard,
    BOverlay,
    BAlert,
    vSelect,
    notAuthorized,
  },
  directives: {
    Ripple,
  },
  mixins: [util],
  data() {
    return {
      permissions: null,
      load: true,
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        'id',
        { key: 'motivo_devolucao', label: 'Motivo Devolução', sortable: true },
        { key: 'descricao', label: 'Descrição', sortable: true },
        { key: 'created_at', label: 'data hora', sortable: true },
      ],
      items: [],
      devolucaoFilter: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    optionsDevolucao() {
      const { items } = this
      const motivos = []
      items.map(item => {
        item.motivo_devolucao ? motivos.push(item.motivo_devolucao) : null
      })
      const removeDuplicates = [...new Set(motivos)]
      return removeDuplicates
    },
    filteredItems() {
      const {
        items, devolucaoFilter: dF,
      } = this
      let list = items
      if (dF.length > 0) {
        list = []
        let newFilterDevolucao = []
        const filterDevolucao = []
        list.length > 0 ? (newFilterDevolucao = list) : (newFilterDevolucao = items)
        for (let i = 0; i <= dF.length - 1; i++) {
          // eslint-disable-next-line no-loop-func
          newFilterDevolucao.map(item => {
            if (item.motivo_devolucao === dF[i]) {
              filterDevolucao.push(item)
            }
          })
          list = filterDevolucao
        }
      }
      this.totalRows = list.length
      return list
    },
  },
  async created() {
    this.permissions = JSON.parse(localStorage.permissoes).devolucao
    this.load = true
    await axios.get('api/v1/devolucao/', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    })
      .then(res => {
        // eslint-disable-next-line array-callback-return
        res.data.dados.devolucoes.map(dt => {
          this.items.push({
            id: dt.id,
            descricao: dt.descricao,
            motivo_devolucao: dt.motivo_devolucao,
            created_at: this.dataHora(dt.created_at),
          })
        })
        // this.items = res.data.dados.devolucoes
        // // eslint-disable-next-line array-callback-return
        // this.items.map(dat => {
        //   // eslint-disable-next-line no-param-reassign
        //   dat.created_at = this.dataHora(dat.created_at, true)
        // })
      })
    // Set the initial number of items
    this.totalRows = this.items.length
    this.load = false
  },
  mounted() {},
  methods: {
    rowClick(item) {
      if (this.permissions['devolucao.detalhe'].permissao) {
        this.$router.push({ name: 'devolucao-edit', params: { id: item.id } })
      } else {
        this.$swal({
          title: 'Acesso Restrito',
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        })
      }
    },
    pushCreate() {
      this.$router.push({ name: 'devolucao-create' })
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
